// @flow
import styled from "styled-components";

export const ProcessingContainer = styled.div`
  height: 100vh;
`;

export const ReferPageContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  background-color: #102337;
  margin: 0 auto;

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
    padding-bottom: 175px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 175px;
  }
`;

export const ReferCodeInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;

  @media only screen and (hover: none) and (pointer: coarse) {
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;
