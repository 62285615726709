// @flow
// $FlowFixMe
import { useState, useEffect } from "react";
import { get } from "lodash";
import promoService from "@tvg/api/pro";

import type { Props, ReferCodeResponse } from "../types";

const useReferCode = (props: Props, isEligible: boolean): ReferCodeResponse => {
  const { accountNumber, promoId, isLogged } = props;
  const [isOpted, setOptinState] = useState(false);
  const [referCode, setReferCode] = useState("");
  const [isOptinError, setOptinError] = useState(false);
  const [optinTypeError, setOptinTypeError] = useState("");
  let mounted = true;

  const fetchReferCode = () => {
    promoService
      .fetchUserPromoStatus(accountNumber, promoId)
      .then((resp) => {
        if (mounted) {
          const code = get(resp.data, "referralCode");
          setReferCode(code);
          setOptinState(true);
          setOptinError(false);
        }
      })
      .catch((error) => {
        const { exception } = error.response.data;
        setOptinTypeError(exception);
        setOptinError(true);
        setOptinState(false);
      });
  };

  useEffect(() => {
    if (isLogged && isEligible && referCode === "" && accountNumber) {
      fetchReferCode();
    }

    if (referCode !== "" && isEligible) {
      setOptinState(true);
    }

    if (!isEligible) {
      setOptinState(false);
      setReferCode("");
    }

    return () => {
      mounted = false;
    };
  }, [isLogged, isEligible, accountNumber]);

  return { referCode, isOpted, isOptinError, optinTypeError };
};

export default useReferCode;
