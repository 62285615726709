// @flow
import React, { Fragment } from "react";
import { setLink } from "@tvg-mar/utils/socialLinksUtils";
import { isEqual } from "lodash";

import type { ContentProps } from "@tvg-mar/promos-types/Template";
import type { OptinError } from "@tvg-mar/promos-types/Promos";
import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";

import {
  ReferPageContainer,
  ReferCodeInput,
  ProcessingContainer
} from "./styled-components";
import Components from "../../_dictionary";

type Props = {
  isLogged: boolean,
  isOpted: boolean,
  isEligible: boolean,
  content: ContentProps,
  referCode: string,
  referPath: string,
  isOptinError: boolean,
  isServiceError: boolean,
  optinTypeError: OptinError,
  userEmail: string,
  isLoading: boolean,
  promoId: number,
  qaLabel: string,
  isRnTablet: boolean
};

const ReferPage = ({
  isLogged,
  isOpted,
  isEligible,
  content,
  referCode,
  referPath,
  isOptinError,
  isServiceError,
  optinTypeError,
  userEmail,
  isLoading,
  promoId,
  qaLabel,
  isRnTablet
}: Props) => (
  <ReferPageContainer data-qa-label={qaLabel}>
    {isLoading ? (
      <ProcessingContainer data-qa-label={`${qaLabel}ProcessingContainer`}>
        <ProcessingOverlay />
      </ProcessingContainer>
    ) : (
      <Fragment>
        <ReferCodeInput
          readOnly
          id="refer-input"
          data-qa-label={`${qaLabel}referCodeInput`}
          type="text"
          value={setLink(referCode, referPath)}
        />
        {content.body.map((blok) =>
          Components({
            ...blok,
            isLogged,
            isOpted,
            isEligible,
            referCode,
            referPath,
            isOptinError,
            isServiceError,
            optinTypeError,
            userEmail,
            promoId,
            isModule: false,
            isRnTablet
          })
        )}
      </Fragment>
    )}
  </ReferPageContainer>
);

ReferPage.defaultProps = {
  isLogged: false,
  isOpted: false,
  isEligible: true,
  content: {},
  referCode: "",
  referPath: "",
  isOptinError: false,
  isServiceError: false,
  optinTypeError: "",
  userEmail: "",
  isLoading: false,
  promoId: 0,
  qaLabel: "referLanding",
  isRnTablet: false
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.isLogged === nextProps.isLogged &&
  prevProps.isOpted === nextProps.isOpted &&
  prevProps.isEligible === nextProps.isEligible &&
  prevProps.isServiceError === nextProps.isServiceError &&
  prevProps.userEmail === nextProps.userEmail &&
  prevProps.referCode === nextProps.referCode &&
  prevProps.isOptinError === nextProps.isOptinError &&
  prevProps.optinTypeError === nextProps.optinTypeError &&
  isEqual(prevProps.content, nextProps.content);

// $FlowFixMe
export default React.memo(ReferPage, areEqual);
