// @flow
import React from "react";
import { Provider } from "react-redux";
// $FlowFixMe
import { AppRegistry } from "react-native-web";
import { BrowserRouter } from "react-router-dom";
import { DesignSystemProvider } from "@tvg/design-system";
import GridFooterEvents from "@tvg/gtm/src/modules/GridFooter";
import MainEvents from "@tvg/gtm/src/modules/Main";
import ReferMainEvent from "@tvg/gtm/src/modules/ReferFriendMain";

import configureStore from "./configureStore";
import Main from "./pages";

const preloadedState = window.__REFERRAL_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
window.promosPageRendered = true;

window.handleNativeMessages = (type, body) => {
  const message = {};
  const postMessage =
    window &&
    window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage;
  if (postMessage) {
    message.type = type;
    if (body) {
      message.body = body;
    }
    postMessage(JSON.stringify(message));
  }
};

GridFooterEvents();
MainEvents();
ReferMainEvent();

const store = configureStore(preloadedState);

const App = () => (
  <DesignSystemProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </DesignSystemProvider>
);

const Render = () => {
  if (document.getElementById("referral")) {
    AppRegistry.registerComponent("App", () => App);

    AppRegistry.runApplication("App", {
      initialProps: {},
      rootTag: document.getElementById("referral")
    });
  }
};

export default Render();
