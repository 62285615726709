// @flow
// $FlowFixMe
import { useState, useEffect } from "react";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import * as mediatorClassic from "@tvg/mediator-classic/src";

const useSessionStatus = (isLogged: boolean, isServiceSuccess: boolean) => {
  const [isLogout, setIsLogout] = useState(false);

  const openLoginModal = () => {
    mediator.base.dispatch({
      type: "OPEN_LOGIN"
    });
  };

  const handleLogOut = () => {
    setIsLogout(true);
  };

  useEffect(() => {
    if (tvgConf().product === "tvg4") {
      mediatorClassic.subscribe("TVG_LOGIN:DO_LOGOUT", handleLogOut);
    } else {
      mediator.base.subscribe("TVG_LOGIN:DO_LOGOUT", handleLogOut);
    }

    return () => {
      if (tvgConf().product === "tvg4") {
        mediatorClassic.unsubscribe("TVG_LOGIN:DO_LOGOUT", handleLogOut);
      } else {
        mediator.base.unsubscribe("TVG_LOGIN:DO_LOGOUT", handleLogOut);
      }
    };
  }, []);

  useEffect(() => {
    if (!isLogged && isServiceSuccess && !isLogout) {
      openLoginModal();
    }
  }, [isLogged, isServiceSuccess]);
};

export default useSessionStatus;
