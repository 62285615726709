// @flow
import type { Story } from "./types";

type SetReferStoriesAction = {
  type: "SET_REFER_STORIES",
  payload: [Story]
};

type SetReferStoryAction = {
  type: "SET_REFER_STORY",
  payload: Story
};

export type ReferActions = SetReferStoryAction | SetReferStoriesAction;

export const setReferStoryAction = (story: Story): SetReferStoryAction => ({
  type: "SET_REFER_STORY",
  payload: story
});

export const setReferStoriesAction = (
  stories: [Story]
): SetReferStoriesAction => ({
  type: "SET_REFER_STORIES",
  payload: stories
});
