// @flow
import React, {
  // $FlowFixMe
  useState
} from "react";
import { useDispatch } from "react-redux";
// $FlowFixMe
import { useNavigate } from "react-router-dom";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import EmailReferral from "@tvg/email-referral";
import type { Device } from "@tvg/types/Device";
import { setEmailReferralOpen } from "../../actions/app";
import isRn from "../utils/isRn";

export const EmailReferralModal = ({
  isOpen = false,
  device
}: {
  isOpen?: boolean,
  device: Device
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCloseDisable, setCloseDisable] = useState(false);

  const onClose = () => {
    dispatch(setEmailReferralOpen(false));
  };

  const closeHandler = () => {
    if (!isCloseDisable) {
      if (navigate) {
        navigate(-1);
      }
      onClose();
    }
  };

  const baseProps = {
    device,
    isOpen,
    onClose: closeHandler,
    onOverlayClick: closeHandler,
    hasOverlay: true,
    qaLabel: "email-referral",
    hasCloseButton: !isCloseDisable
  };

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          isFullHeight: true,
          hasRoundedCorners: false,
          animation: "right",
          hasHeader: false,
          offsetTop: 0
        }
      : {
          ...baseProps,
          title: "Email Referral",
          titleType: "default",
          animation: "fade",
          isFullWidth: false,
          isFullHeight: false,
          hasHeader: true,
          offsetTop: 40,
          offsetBottom: 40,
          fixedWidth: "480px"
        };

  return (
    <ModalV2 {...props}>
      {() => (
        <EmailReferral
          device={device}
          isNative={isRn}
          onNavigationBackClick={onClose}
          onNavigationBackDisable={setCloseDisable}
        />
      )}
    </ModalV2>
  );
};

export default EmailReferralModal;
