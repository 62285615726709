// @flow

export type App = {
  promosList: Array<{ [string]: string }>
};

export type setPromosAction = {
  type: "PROMOS_LIST_SET",
  payload: Array<{ [string]: string }>
};

export type SetBetaOptinAction = {
  type: "SET_BETA_OPTIN",
  payload: boolean
};

export type SetEmailReferralOpenAction = {
  type: "SET_EMAIL_REFERRAL_OPEN",
  payload: boolean
};

export type AppActions =
  | setPromosAction
  | SetBetaOptinAction
  | SetEmailReferralOpenAction;

export const setPromosList = (
  promosList: Array<{ [string]: string }>
): setPromosAction => ({
  type: "PROMOS_LIST_SET",
  payload: promosList
});

export const setBetaOptin = (isBeta: boolean): SetBetaOptinAction => ({
  type: "SET_BETA_OPTIN",
  payload: isBeta
});

export const setEmailReferralOpen = (
  isOpen: boolean
): SetEmailReferralOpenAction => ({
  type: "SET_EMAIL_REFERRAL_OPEN",
  payload: isOpen
});
