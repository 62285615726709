// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("SOCIAL_LINK_CLICK", (data) => {
    pushToDataLayer({
      event: "referral",
      gaEventAction: "Referral Link Clicked",
      gaEventCategory: "Refer A Friend",
      gaEventLabel: get(data, "payload.gaEventLabel", undefined), // e.g 'copy link', 'twitter' etc
      module: "Referral Share Links"
    });
  });

  mediatorChannels.base.subscribe("RAF_NAVIGATION_CLICK", (data) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: get(data, "payload.gaEventLabel", undefined), // (i.e. learn more, opt in, bet now)
      menu: undefined,
      module: get(data, "payload.module", undefined), // (i.e. promo hub landing, promo offer page, promo modal, refer a friend)
      sport: undefined,
      microApp: get(data, "payload.microApp", undefined), // Refer a Friend, non-Microapp
      destinationUrl: get(data, "payload.destinationUrl", undefined) // destination Url
    });
  });

  mediatorChannels.base.subscribe("RAF_SITE_CLICK", (data) => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: get(data, "payload.gaEventAction", undefined), // pass event name 'Join Now'
      gaEventLabel: get(data, "payload.gaEventLabel", undefined), // Button label
      module: get(data, "payload.module", undefined),
      tag: undefined,
      microApp: get(data, "payload.microApp", undefined)
    });
  });
};
