// @flow

import type { Story } from "./types";
import type { ReferActions } from "./actions";

export type State = {
  stories: {
    [string]: Story
  }
};

export const initialState: State = {
  stories: {}
};

export default (state: State = initialState, action: ReferActions) => {
  switch (action.type) {
    case "SET_REFER_STORY":
      return {
        ...state,
        stories: {
          ...state.stories,
          [action.payload.slug]: action.payload
        }
      };
    default: {
      return state;
    }
  }
};
