// @flow
import { combineReducers } from "redux";

import referReducer, {
  type State as ReferState,
  initialState as initialReferState
} from "@tvg/refer/src/reducers";

import capiReducer, {
  type State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import appReducer, {
  type State as AppState,
  initialState as initialAppState
} from "./App/appReducer";

import userDataReducer, {
  type State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

export type State = {
  capi: CapiState,
  app: AppState,
  userData: UserState,
  refer: ReferState
};

export const initialState = {
  capi: initialCapiState,
  app: initialAppState,
  userData: initialUserState,
  refer: initialReferState
};

export default combineReducers({
  capi: capiReducer,
  app: appReducer,
  userData: userDataReducer,
  refer: referReducer
});
