// @flow
import type { UserInfo } from "@tvg/types/User";
import type { UserActions } from "../../actions/user";
import type { UserOptedInPromos } from "../../types";

export type State = {
  error: boolean,
  hasRequested: boolean,
  logged: boolean,
  logging: boolean,
  user: UserInfo,
  wasLogin: boolean,
  optedInPromos: UserOptedInPromos,
  returningUser: boolean,
  gotPromos: boolean,
  loadingPromos: boolean
};

export const initialState = {
  error: false,
  hasRequested: false,
  logged: false,
  logging: false,
  loginPin: 0,
  tvg3token: "",
  userLogin: false,
  userLogout: true,
  allPromos: [],
  user: {
    accountNumber: "",
    emailAddress: "",
    firstName: "",
    homeAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    lastName: "",
    mailingAddress: {
      streetNumber: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      stateAbbr: ""
    },
    primaryPhone: "",
    profile: "PORT-Generic",
    signalProviderId: "",
    status: "",
    transportId: "",
    userName: "",
    wagerStatus: 0,
    accountRoles: ["USER"]
  },
  wasLogin: false,
  optedInPromos: {},
  returningUser: false,
  gotPromos: false,
  loadingPromos: false
};

const userDataReducer = (state: State = initialState, action: UserActions) => {
  switch (action.type) {
    case "USER_DATA_UPDATE": {
      return { ...state, ...action.payload };
    }
    case "USER_PRIOR_LOGIN_STATUS": {
      return { ...state, returningUser: action.payload.returningUser };
    }
    case "USER_PROMOS_SUCCESS": {
      return {
        ...state,
        ...action.payload,
        gotPromos: true,
        loadingPromos: false
      };
    }
    case "USER_PROMOS_LOADING": {
      return { ...state, loadingPromos: true };
    }

    case "USER_PROMOS_CLEAR": {
      return {
        ...state,
        optedInPromos: {},
        gotPromos: false,
        loadingPromos: false
      };
    }
    default: {
      return state;
    }
  }
};

export default userDataReducer;
