// @flow
import React from "react";
import { type RouterHistory } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import type {
  NavigationEvent,
  SiteClickEvent
} from "@tvg-mar/promos-types/GTMHandlers";

import Error from "../../_molecules/Error";
import ErrorPageContainer from "./styled-components";

type Props = {
  history: RouterHistory,
  title: string,
  description: string,
  buttonText: string,
  pageOrigin: string,
  microApp: string,
  module: string,
  navigationEvent: NavigationEvent,
  siteClickEvent: SiteClickEvent,
  qaLabel: string
};

const ErrorPage = ({
  title,
  description,
  buttonText,
  pageOrigin,
  history,
  microApp,
  navigationEvent,
  siteClickEvent,
  module,
  qaLabel
}: Props) => {
  const handleClick = (): void => {
    const homePath = "/";

    mediator.base.dispatch({
      type: navigationEvent,
      payload: {
        gaEventLabel: buttonText,
        module: pageOrigin,
        microApp,
        destinationUrl: homePath
      }
    });

    mediator.base.dispatch({
      type: siteClickEvent,
      payload: {
        gaEventLabel: buttonText,
        module,
        microApp
      }
    });

    if (tvgConf().product === "tvg4") {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: homePath }
      });
    } else {
      history.push(homePath);
    }
  };

  return (
    <ErrorPageContainer data-qa-label={qaLabel}>
      <Error
        title={title}
        description={description}
        buttonText={buttonText}
        onClick={handleClick}
        qaLabel={`${qaLabel}Error`}
      />
    </ErrorPageContainer>
  );
};

ErrorPage.defaultProps = {
  title: "",
  description: "",
  buttonText: "",
  pageOrigin: "",
  microApp: "",
  module: "",
  navigationEvent: "CTA_NAVIGATION_CLICK",
  siteClickEvent: "CTA_SITE_CLICK",
  qaLabel: "errorPage"
};

export default withRouter(ErrorPage);
