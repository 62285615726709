// @flow
import React, { Component } from "react";
import type { Dispatch } from "redux";
import { get, has } from "lodash";

import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import { PageHeader } from "@tvg/design-system";
import ReferComponent from "@tvg/refer";
import type { Device } from "@tvg/types/Device";

import EmailReferralModal from "./EmailReferralModal";
import sendGTMPageView from "../utils/gtm";
import isRn, { isRnTablet } from "../utils/isRn";

type Props = {
  isLogged: boolean,
  accountNumber: string,
  returningUser: boolean,
  userEmail: string,
  homeState: string,
  promoId: number,
  dispatch: Dispatch<*>,
  initialRenderComplete: boolean,
  isEmailReferralOpen: boolean,
  isEmailReferralEnable: boolean,
  device: Device
};

type State = {
  isLoading: boolean,
  pageViewSet: boolean
};

// eslint-disable-next-line
export class Refer extends Component<Props, State> {
  static defaultProps = {
    isLogged: false,
    accountNumber: "",
    returningUser: false,
    userEmail: "",
    homeState: "",
    promoId: 0,
    initialRenderComplete: false,
    isEmailReferralEnable: false,
    device: "mobile"
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      pageViewSet: false
    };
  }

  componentDidMount() {
    this.setPageView();
  }

  shouldComponentUpdate(nextProps: Props) {
    return (
      nextProps.isLogged !== this.props.isLogged ||
      nextProps.promoId !== this.props.promoId ||
      nextProps.accountNumber !== this.props.accountNumber ||
      nextProps.returningUser !== this.props.returningUser ||
      nextProps.userEmail !== this.props.userEmail ||
      nextProps.homeState !== this.props.homeState ||
      nextProps.initialRenderComplete !== this.props.initialRenderComplete ||
      nextProps.isEmailReferralOpen !== this.props.isEmailReferralOpen
    );
  }

  componentDidUpdate() {
    this.setPageView();
  }

  handleBackButtonClick = () => {
    if (
      isRn &&
      typeof window !== "undefined" &&
      has(window, "handleNativeMessages")
    ) {
      window.handleNativeMessages("NAVIGATION_GO_BACK", {});
    }
  };

  setPageView = () => {
    if (typeof window !== "undefined" && !this.state.pageViewSet) {
      const page = get(window, "location.pathname", "");
      // $FlowFixMe
      sendGTMPageView(this.props, page);
      this.setState((prevState) => ({ ...prevState, pageViewSet: true }));
    }
  };

  render() {
    return (
      <Containers.Page isFixed={false} data-qa-label="referPage">
        <Containers.Header
          device={this.props.device}
          isVisible={isRn && this.props.isEmailReferralEnable}
        >
          <PageHeader
            title="Refer A Friend"
            onBack={this.handleBackButtonClick}
          />
        </Containers.Header>
        <ReferComponent
          isLogged={this.props.isLogged}
          accountNumber={this.props.accountNumber}
          returningUser={this.props.returningUser}
          userEmail={this.props.userEmail}
          homeState={this.props.homeState}
          dispatch={this.props.dispatch}
          promoId={this.props.promoId}
          isRnTablet={isRnTablet}
        />
        <EmailReferralModal
          device={this.props.device}
          isOpen={this.props.isEmailReferralOpen}
        />
      </Containers.Page>
    );
  }
}

export default Refer;
