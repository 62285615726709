// @flow
// $FlowFixMe
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { get, isEmpty, isEqual } from "lodash";
import ReferEvents from "@tvg-mar/promos-gtm/src/modules/ReferFriend";
import ReferPage from "@tvg-mar/tvg-promos-atomic-ui/_templates/ReferPage";
import ReferFriendPage from "@tvg-mar/tvg-promos-atomic-ui/_templates/ReferFriendPage";
import ErrorPage from "@tvg-mar/tvg-promos-atomic-ui/_templates/ErrorPage";

import {
  useEligibilityCheck,
  useReferCode,
  useSessionStatus,
  useStoryblokService,
  useReferralStatus
} from "./hooks";

import type { Props } from "./types";

ReferEvents();

export const ReferComponent = (props: Props) => {
  const {
    isLogged,
    promoId,
    dispatch,
    stories,
    errorTitle,
    errorDescription,
    errorBtnTextHome,
    referralsEmptyTitle,
    referralsEmptyDescription,
    referralCenterOpening,
    enableReferralCenter,
    enableOverlayReferralStatus,
    accountNumber,
    isRnTablet
  } = props;
  const { isServiceSuccess, isServiceError } = useStoryblokService(
    promoId,
    dispatch
  );
  const { isEligible } = useEligibilityCheck(props);
  const { referCode, isOpted, isOptinError, optinTypeError } = useReferCode(
    props,
    isEligible
  );
  const showReferPage = (stories !== {} && !isServiceError) || isServiceSuccess;
  const {
    isLoading,
    referralStatus,
    totalBonusAmount,
    totalCompletedReferrals
  } = useReferralStatus({
    accountNumber,
    enableFetchService: showReferPage && enableReferralCenter
  });

  useSessionStatus(isLogged, isServiceSuccess);

  return (
    <Fragment>
      {isServiceError && (
        <ErrorPage
          title={errorTitle}
          description={errorDescription}
          buttonText={errorBtnTextHome}
          pageOrigin="/referral"
          microApp="Refer"
          module="Refer a friend - Referrer"
          navigationEvent="RAF_NAVIGATION_CLICK"
          siteClickEvent="RAF_SITE_CLICK"
        />
      )}
      {showReferPage && !enableReferralCenter && (
        <ReferPage
          data-qa-label="referPage"
          isLogged={isLogged}
          isOpted={isOpted}
          isEligible={isEligible}
          content={get(stories[promoId.toString()], "content", {
            body: []
          })}
          referCode={referCode}
          referPath={get(stories[promoId.toString()], "content.referPath", "")}
          isOptinError={isOptinError}
          isServiceError={isServiceError}
          optinTypeError={optinTypeError}
          userEmail={props.userEmail}
          isLoading={!isServiceSuccess && isEmpty(stories)}
          promoId={promoId}
        />
      )}
      {showReferPage && enableReferralCenter && (
        <ReferFriendPage
          isLogged={isLogged}
          isOpted={isOpted}
          isEligible={isEligible}
          content={get(stories[promoId.toString()], "content", {
            body: []
          })}
          referCode={referCode}
          referPath={get(stories[promoId.toString()], "content.referPath", "")}
          isOptinError={isOptinError}
          isServiceError={isServiceError}
          optinTypeError={optinTypeError}
          userEmail={props.userEmail}
          isLoading={!isServiceSuccess && isEmpty(stories)}
          isLoadingReferrals={isLoading}
          promoId={promoId}
          enableOverlayReferralStatus={enableOverlayReferralStatus}
          referralsEmptyTitle={referralsEmptyTitle}
          referralsEmptyDescription={referralsEmptyDescription}
          referralCenterOpening={referralCenterOpening}
          referrals={referralStatus}
          totalBonusAmount={totalBonusAmount}
          totalCompletedReferrals={totalCompletedReferrals}
          isRnTablet={isRnTablet}
        />
      )}
    </Fragment>
  );
};

ReferComponent.defaultProps = {
  isLogged: false,
  returningUser: false,
  homeState: "",
  userEmail: "",
  accountNumber: "",
  eligiblePromos: {},
  promoId: 0,
  stories: {},
  errorTitle: "",
  errorDescription: "",
  errorBtnTextHome: "",
  referralsEmptyTitle: "",
  referralsEmptyDescription: "",
  referralCenterMessage: "",
  enableReferralCenter: false,
  enableOverlayReferralStatus: false,
  isRnTablet: false
};

const mapStateToProps = (store) => ({
  eligiblePromos: get(store, "userData.optedInPromos", ""),
  stories: get(store, "refer.stories", {}),
  enableReferralCenter: get(
    store,
    "capi.featureToggles.enableReferralCenter",
    false
  ),
  enableOverlayReferralStatus: get(
    store,
    "capi.featureToggles.enableOverlayReferralStatus",
    false
  ),
  referralsEmptyTitle: get(store, "capi.messages.referralsEmptyTitle", ""),
  referralsEmptyDescription: get(
    store,
    "capi.messages.referralsEmptyDescription",
    ""
  ),
  referralCenterOpening: get(store, "capi.messages.referralCenterOpening", ""),
  errorTitle: get(store, "capi.messages.promosErrorPageTitle", ""),
  errorDescription: get(store, "capi.messages.promosErrorPageDescription", ""),
  errorBtnTextHome: get(
    store,
    "capi.messages.promosErrorPageButtonTextToHome",
    ""
  )
});

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.isLogged === nextProps.isLogged &&
  isEqual(prevProps.eligiblePromos, nextProps.eligiblePromos) &&
  isEqual(prevProps.stories, nextProps.stories) &&
  prevProps.userEmail === nextProps.userEmail;

// $FlowFixMe
export default connect(mapStateToProps)(React.memo(ReferComponent, areEqual));
