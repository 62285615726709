// @flow
// $FlowFixMe
import { useState, useEffect } from "react";
import type { Dispatch } from "redux";
import StoryblokService from "@tvg-mar/storyblok-bridge";

import { setReferStoryAction } from "../actions";

const storyblokBridge = new StoryblokService();

type UseStoryblokService = {
  isServiceError: boolean,
  isServiceSuccess: boolean
};

const useStoryblokService = (
  slug: string,
  dispatch: Dispatch<*>
): UseStoryblokService => {
  const [isServiceError, setError] = useState(false);
  const [isServiceSuccess, setSuccess] = useState(false);
  let mounted = true;

  const fetchStory = () => {
    storyblokBridge
      .get(`cdn/stories/referral/${slug}`)
      .then((resp) => {
        if (mounted) {
          setSuccess(true);
          setError(false);
          dispatch(setReferStoryAction(resp.data.story));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(true);
        setSuccess(false);
      });
  };

  useEffect(() => {
    if (slug !== 0) {
      fetchStory();
    }

    return () => {
      mounted = false;
    };
  }, [slug]);

  return { isServiceSuccess, isServiceError };
};

export default useStoryblokService;
