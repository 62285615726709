// @flow
// $FlowFixMe
import { useEffect, useRef } from "react";

// custom hook for getting previous value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
