// @flow
// $FlowFixMe
import { useState } from "react";

const useForceUpdate = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);
  return () => setValue((val) => val + 1);
};

export default useForceUpdate;
