// @flow
import tvgConf from "@tvg/conf";

export default (data: mixed) => {
  if (typeof window !== "undefined") {
    if (["iosnative", "androidnative"].includes(tvgConf().product)) {
      window.handleNativeMessages("PROMOS_GTM_WEBVIEW", data);
    } else if (window.dataLayer) {
      window.dataLayer.push(data);
    }
  }
};
